import Img, { FluidObject } from "gatsby-image"
import React from "react"
import Slider, { Settings as SliderSettings } from "react-slick"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./LogosSlider.scss"

interface ILogosSliderProps {
  logos: ILogo[]
}

export interface ILogo {
  alt: string
  fluidObject: FluidObject | FluidObject[] | undefined
  height: number
  publicURL?: string
}
const LogosSlider: React.FunctionComponent<ILogosSliderProps> = props => {
  const settings: SliderSettings = {
    infinite: true,
    speed: 1000,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          infinite: true,
          speed: 1000,
          cssEase: "linear",
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          accessibility: true,
          autoplay: true,
          autoplaySpeed: 1500,
          swipe: false,
          pauseOnFocus: false,
          pauseOnHover: false,
        },
      },
    ],
  }
  return (
    <Slider {...settings} className="logo-slider">
      {props.logos.map(logo => (
        <div key={`slide-${logo.alt}`}>
          <article className="slides_item">
            <div className="img_container" style={{ height: logo.height }}>
              {logo.fluidObject ? (
                <Img
                  fluid={logo.fluidObject}
                  alt={logo.alt}
                  loading="eager"
                  imgStyle={{ objectFit: "contain" }}
                  className="slider_picture"
                />
              ) : (
                <img src={logo.publicURL} height={logo.height} />
              )}
            </div>
          </article>
        </div>
      ))}
    </Slider>
  )
}

export default LogosSlider
